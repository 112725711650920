import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/Clients/StephaneCatoire/Gastby/scatoire-perso/src/components/blog-post-layout.jsx";
import { graphql } from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wish to all of you a very happy new year 2022 !
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/bc3ae/ExcelAddresses.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.263803680981596%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTElEQVQY03WO207CQBRF+/9foy+GBEW8UJQI2svMkNq0dhDBmc617bSdIhgwJqhxPezkPKy9jzOYXvfc/pk7uJzd9maTk/vx5ePoynP7k+HpTT+M5gl+GcGH/tS9mN5e+5Pz2Wjo3Y3njy6aOZUqVJ7nhFa6LFTJmaRropjQQnLK3l6Xq9VKS1WqolSFFsqapq3q1jStaRy8wOv3d8YZoYQLzgXPsoxQygVXWmmthRCccyEEIeQr94eUQkrHe/KCMIyiKM+ZtV1Vlr7nxXFMKdVaFwfsgbZpuq5r27b7xgkCHyIYx88YY2ttbQwEIEnSPM+VUvVB2PyDA0AIAEiThFK62WyMMQjBKIowxpQSpZW19lg47nLCIEAQpWlaVdVutzPGQAhDAF6yTErZ/Fn+Ifu+j+Z7eblcSim11miOFosFY6yu64/t9terx/InchW3NZBe9VgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "happy",
            "title": "happy",
            "src": "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/a6d36/ExcelAddresses.png",
            "srcSet": ["/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/222b7/ExcelAddresses.png 163w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/ff46a/ExcelAddresses.png 325w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/a6d36/ExcelAddresses.png 650w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/e548f/ExcelAddresses.png 975w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/bc3ae/ExcelAddresses.png 1268w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* Footer to allow translation to be copied on each post
          Boring but wainting for a better solution */
    }



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      